import { useCallback } from 'react'
import { Modal, ModalBody, Text, Button, Flex, InjectedModalProps, Box, Image } from '@pancakeswap/uikit'
import { Trans, useTranslation } from '@pancakeswap/localization'
import isEmpty from 'lodash/isEmpty'
import groupBy from 'lodash/groupBy'
import { useAllSortedRecentTransactions } from '../../../state/transactions/hooks'
import { TransactionDetails } from 'state/transactions/reducer'
import { useAppDispatch } from '../../../state'
import { clearAllTransactions } from '../../../state/transactions/actions'
import { chains } from '../../../utils/wagmi'
import { useWeb3React } from '@pancakeswap/wagmi'
import { AutoRow } from '../../Layout/Row'
import Transaction from './Transaction'
import ConnectWalletButton from '../../ConnectWalletButton'

function renderTransactions(transactions: TransactionDetails[], chainId: number) {
  return (
    <Flex flexDirection="column">
      {transactions.map((tx) => {
        return <Transaction key={tx.hash + tx.addedTime} tx={tx} chainId={chainId} />
      })}
    </Flex>
  )
}

const TransactionsModal: React.FC<React.PropsWithChildren<InjectedModalProps>> = ({ onDismiss }) => {
  const { account } = useWeb3React()
  const dispatch = useAppDispatch()
  const sortedRecentTransactions = useAllSortedRecentTransactions()

  const { t } = useTranslation()

  const hasTransactions = !isEmpty(sortedRecentTransactions)

  const clearAllTransactionsCallback = useCallback(() => {
    dispatch(clearAllTransactions())
  }, [dispatch])

  return (
    <Modal title={t('Recent Transactions')} onDismiss={onDismiss} width={['100%', null, null, '400px !important']}>
      {account ? (
        <ModalBody height={400}>
          <Box height="100%">
            {hasTransactions ? (
              <>
                {Object.entries(sortedRecentTransactions).map(([chainId, transactions]) => {
                  const chainIdNumber = Number(chainId)
                  const groupedTransactions = groupBy(Object.values(transactions), (trxDetails) =>
                    Boolean(trxDetails.receipt),
                  )

                  const confirmed = groupedTransactions.true ?? []
                  const pending = groupedTransactions.false ?? []

                  return (
                    <div key={`transactions#${chainIdNumber}`}>
                      {/* <Text fontSize="16px" fontWeight={600} color="textSubtle" mb="8px">
                        {chains.find((c) => c.id === chainIdNumber)?.name ?? 'Unknown network'}
                      </Text> */}
                      {renderTransactions(pending, chainIdNumber)}
                      {renderTransactions(confirmed, chainIdNumber)}
                    </div>
                  )
                })}
              </>
            ) : (
              <Flex
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                gap="8px"
                height="100%"
                flexGrow={1}
              >
                <Box>
                  <Image src="/images/empty.png" width={196} height={130} />
                </Box>
                <Text fontSize="12px" fontWeight={400} color="#b4b4b4">
                  No transaction yet
                </Text>
              </Flex>
            )}
          </Box>

          {/* <Box marginTop="auto !important" width="100%">
            <ConnectWalletButton
              style={{
                borderRadius: '8px',
                fontSize: 14,
                fontWeight: 600,
              }}
              width="100% !important"
              marginTop="32px"
              height={40}
              paddingX={16}
              paddingY={8}
            >
              <Text fontSize="14px" fontWeight={400} display={['none', , , 'block']}>
                Connect Wallet
              </Text>
              <Text fontSize="14px" fontWeight={400} display={['block', , , 'none']}>
                Connect
              </Text>
            </ConnectWalletButton>
          </Box> */}
        </ModalBody>
      ) : (
        <ModalBody height={400}>
          <Flex flexDirection="column" alignItems="center" gap="64px" height="100%">
            <Flex flexDirection="column" flexGrow={1} justifyContent="center" alignItems="center" gap="8px">
              <Box>
                <Image src="/images/empty.png" width={196} height={130} />
              </Box>
              <Text fontSize="12px" fontWeight={400} color="#b4b4b4">
                No transaction yet
              </Text>
            </Flex>
            <Box width="100%" style={{ display: 'flex' }}>
              <ConnectWalletButton width="100% !important" height={52} paddingX={16} paddingY={8} rounded>
                <Text fontSize="14px" fontWeight={600} color="dark" display={['none', , , 'block']}>
                  Connect Wallet
                </Text>
                <Text fontSize="14px" fontWeight={600} color="dark" display={['block', , , 'none']}>
                  Connect
                </Text>
              </ConnectWalletButton>
            </Box>
          </Flex>
        </ModalBody>
      )}
    </Modal>
  )
}

export default TransactionsModal
