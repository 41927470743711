import styled from 'styled-components'
import {
  Text,
  Flex,
  Heading,
  IconButton,
  ArrowBackIcon,
  NotificationDot,
  QuestionHelper,
  Box,
  useMatchBreakpoints,
} from '@pancakeswap/uikit'
import { useExpertModeManager } from '../../state/user/hooks'
import GlobalSettings from '../Menu/GlobalSettings'
import Link from 'next/link'
import Transactions from './Transactions'
import { SettingsMode } from '../Menu/GlobalSettings/types'
import { SettingHeaderIcon, SettingIcon } from '../../../public/icon'

interface Props {
  title: string
  subtitle?: string
  helper?: string
  backTo?: string | (() => void)
  noConfig?: boolean
}

const AppHeaderContainer = styled(Flex)`
  align-items: center;
  justify-content: space-between;
  padding: 0px 0px 16px 0px;
  width: 100%;
  background: #1a1a1a;
`

const AppHeader: React.FC<React.PropsWithChildren<Props>> = ({ title, subtitle, helper, backTo, noConfig = false }) => {
  const [expertMode] = useExpertModeManager()
  const { isMobile } = useMatchBreakpoints()

  return (
    <>
      {/* <Box style={{ width: '100%', display: 'block', backgroundColor: '#1A1A1A' }}>
        {backTo &&
          (typeof backTo === 'string' ? (
            <Link passHref href={backTo} prefetch={false}>
              <IconButton as="a" scale="sm">
                <ArrowBackIcon width="32px" />
              </IconButton>
            </Link>
          ) : (
            <IconButton scale="sm" variant="text" onClick={backTo}>
              <ArrowBackIcon width="32px" />
            </IconButton>
          ))}
      </Box>
      <AppHeaderContainer>
        <Flex alignItems="center" width="100%" style={{ gap: '16px' }}>
          <Flex flexDirection="column" width="100%">
            <Flex mb="8px" alignItems="center" justifyContent="space-between">
              <Flex>
                <Heading fontSize="16px !important" fontWeight="500 !important" as="h6">
                  {title}
                </Heading>
                {helper && <QuestionHelper text={helper} ml="4px" placement="top-start" />}
              </Flex>
              {!noConfig && (
                <Flex alignItems="center">
                  <NotificationDot show={expertMode}>
                    <GlobalSettings
                      mode={SettingsMode.SWAP_LIQUIDITY}
                      icon={
                        <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M12 7.5a4.5 4.5 0 100 9 4.5 4.5 0 000-9zm0 7.5a3 3 0 110-6 3 3 0 010 6zm10.307-4.95a.75.75 0 00-.365-.505L19.146 7.95l-.011-3.152a.751.751 0 00-.266-.57 10.49 10.49 0 00-3.442-1.938.75.75 0 00-.606.056l-2.82 1.576-2.825-1.58a.75.75 0 00-.606-.056A10.51 10.51 0 005.13 4.233a.75.75 0 00-.265.569l-.014 3.155L2.055 9.55a.75.75 0 00-.365.506 9.982 9.982 0 000 3.897.75.75 0 00.365.506l2.797 1.594.011 3.152a.75.75 0 00.265.57 10.491 10.491 0 003.443 1.938.75.75 0 00.605-.056L12 20.077l2.824 1.58a.742.742 0 00.607.054 10.509 10.509 0 003.438-1.944.75.75 0 00.266-.569l.014-3.155 2.796-1.593a.753.753 0 00.365-.507 9.983 9.983 0 00-.003-3.892zm-1.406 3.274l-2.679 1.523a.75.75 0 00-.28.281c-.055.094-.112.194-.17.287a.744.744 0 00-.115.395l-.014 3.023a8.986 8.986 0 01-2.378 1.341l-2.702-1.506a.75.75 0 00-.367-.093H11.838a.757.757 0 00-.385.093l-2.704 1.51a9 9 0 01-2.385-1.334l-.01-3.019a.75.75 0 00-.115-.396 5.374 5.374 0 01-.169-.287.758.758 0 00-.28-.286l-2.682-1.528a8.484 8.484 0 010-2.649l2.674-1.526a.75.75 0 00.281-.281c.054-.094.112-.194.17-.287a.744.744 0 00.114-.395l.014-3.023A8.99 8.99 0 018.74 3.826l2.698 1.506a.75.75 0 00.384.093h.341a.758.758 0 00.385-.093l2.703-1.51c.859.32 1.663.77 2.385 1.334l.01 3.019c.001.14.04.277.115.396.058.093.115.187.169.286.066.12.163.219.281.287l2.681 1.527c.141.879.143 1.774.005 2.653h.004z"
                            fill="#596780"
                          />
                        </svg>
                      }
                    />
                  </NotificationDot>
                  <Transactions />
                </Flex>
              )}
            </Flex>
            <Flex alignItems="center">
              <Text color="textSubtle" fontSize="12px">
                {subtitle}
              </Text>
            </Flex>
          </Flex>
        </Flex>
      </AppHeaderContainer> */}
      <AppHeaderContainer>
        <Flex alignItems="center" justifyContent="space-between" width="100%" style={{ gap: '16px' }}>
          <Box style={{ width: '104px', display: 'block', backgroundColor: '#1A1A1A' }}>
            {backTo &&
              (typeof backTo === 'string' ? (
                <Link passHref href={backTo} prefetch={false}>
                  <IconButton as="a" scale="sm">
                    <ArrowBackIcon />
                  </IconButton>
                </Link>
              ) : (
                <IconButton scale="sm" variant="text" onClick={backTo}>
                  <ArrowBackIcon />
                </IconButton>
              ))}
          </Box>
          <Flex flexGrow={1} flexDirection="column" gap="2px" justifyContent="center" alignItems="center">
            <Text color="#FBFBFB" fontSize={isMobile ? '18px' : '24px'} fontWeight="700" textAlign="center">
              {title}
            </Text>
            {subtitle && (
              <Text color="#b4b4b4" fontSize="12px" fontWeight="400" textAlign="center">
                {subtitle}
              </Text>
            )}
          </Flex>
          {!noConfig ? (
            <Flex alignItems="center">
              <NotificationDot show={expertMode}>
                <GlobalSettings mode={SettingsMode.SWAP_LIQUIDITY} icon={<SettingIcon />} />
              </NotificationDot>
              <Transactions />
            </Flex>
          ) : (
            <Flex width="104px" />
          )}
        </Flex>
      </AppHeaderContainer>
    </>
  )
}

export default AppHeader
